import type { NextPage } from 'next';

import { BlockType } from '@web/blocks';

import { ContentBlock } from '@web/components/block-list/content-block';
import { HeroFrontPage } from '@web/components/hero-front-page/hero-front-page';

import TemplateLayout from '@web/layouts/template/template';

import { Link } from '@web/services/umbraco/types/basic/Link';
import { QBankImageType } from '@web/services/umbraco/types/basic/QBank';
import { HTMLString } from '@web/services/umbraco/types/basic/RichTextContent';
import { UmbracoPage } from '@web/services/umbraco/types/documents/UmbracoDocument';
import { SingleTextBlock } from '@web/services/umbraco/types/elements/SingleText';

const contentAlias = 'siteRoot' as const;

export type FrontpageData = UmbracoPage<
	typeof contentAlias,
	{
		tagline: HTMLString;
		action: Link;
		/** Minimum 4 items */
		images: [QBankImageType, QBankImageType, QBankImageType, QBankImageType, ...QBankImageType[]];
		/** Exactly 4 items */
		uspList: [
			SingleTextBlock,
			SingleTextBlock,
			SingleTextBlock,
			SingleTextBlock,
		];
		blocks: BlockType[];
	}
>;

const FrontpageTemplate: NextPage<{ pageData: FrontpageData }> = ({ pageData }) => {
	return (
		<TemplateLayout>
			<HeroFrontPage
				tagline={pageData.properties.tagline}
				action={pageData.properties.action}
				images={pageData.properties.images}
				uspList={pageData.properties.uspList}
			/>
			{pageData.properties.blocks && <ContentBlock blocks={pageData.properties.blocks} />}
		</TemplateLayout>
	);
};

export default FrontpageTemplate;
