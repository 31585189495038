/* eslint-disable no-param-reassign */
import { useEffect, useRef, useState } from 'react';
import isChromatic from 'chromatic/isChromatic';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { getPallette, vars } from '@dansk-metal/theme';
import { Container, Icon } from '@dansk-metal/ui';
import { MOTIONS } from '@dansk-metal/utils/animations';

import HeroLine from '@web/components/hero-line/hero-line';
import { Aspect } from '@web/components/media/aspectRatios';
import { QBankMediaDisplay } from '@web/components/qbank-media/qbank-media';
import { Theme } from '@web/components/theme/theme';
import { UmbracoLink } from '@web/components/umbraco-link/umbraco-link';

import { ColorTheme } from '@web/services/umbraco/types/basic/Color';
import { Link } from '@web/services/umbraco/types/basic/Link';
import { QBankImageType } from '@web/services/umbraco/types/basic/QBank';
import { HTMLString } from '@web/services/umbraco/types/basic/RichTextContent';
import { SingleTextBlock } from '@web/services/umbraco/types/elements/SingleText';

import { useWindowSize } from '@web/hooks/useWindowSize';

import styles from './hero-front-page.module.scss';

export interface HeroFrontPageProps {
	tagline: HTMLString;
	action: Link;
	/** Minimum 4 items */
	images: [QBankImageType, QBankImageType, QBankImageType, QBankImageType, ...QBankImageType[]];
	/** Exactly 4 items */
	uspList: [SingleTextBlock, SingleTextBlock, SingleTextBlock, SingleTextBlock];
}

type ExtendedQBankImageType = QBankImageType & { index: number };

export function HeroFrontPage(props: HeroFrontPageProps) {
	const { tagline, action, uspList, images } = props;
	const [currentImages, setCurrentImages] = useState<ExtendedQBankImageType[]>([]);
	const currentImagesRef = useRef(currentImages);
	currentImagesRef.current = currentImages;
	const [introAnimation, setIntroAnimation] = useState(true);
	const imagesRef = useRef<HTMLDivElement>(null);
	const size = useWindowSize();

	const getImageAnimation = (index) => {
		return {
			transition: { duration: 1 },
			initial: { opacity: 0 },
			animate: { opacity: 1, transition: { delay: (introAnimation ? 0.5 : 1.5) + index * 0.25 } },
			exit: { opacity: 0 },
		};
	};

	useEffect(() => {
		const { current } = imagesRef;
		const isDesktop = window.innerWidth >= 960;
		let start: number | undefined;
		let maxImages = 4;
		let startIndex = 0;
		let galleryInterval: ReturnType<typeof setInterval>;

		const updateImages = () => {
			const endIndex = startIndex + maxImages;
			setCurrentImages(continousSlice(images, startIndex, endIndex));
			startIndex += isDesktop ? 2 : 1;
		};

		const moveGallery = () => {
			const timestamp = new Date().getTime();

			if (current) {
				if (start === undefined) {
					start = timestamp;
				}

				const elapsed = timestamp - start;

				if (current.children.length > 0) {
					const count = 0.1 * elapsed;
					Array.from(current.children).forEach((el: HTMLDivElement) => {
						if (!el.dataset.positioned) {
							el.dataset.positioned = 'true';
							const ps = el?.previousSibling as HTMLElement;
							if (ps) {
								const width = ps.getBoundingClientRect().width;
								const left = parseInt(ps.style.left.replace('px', ''), 10);
								el.style.left = `${width + left}px`;
							} else {
								el.style.left = '0px';
							}
						}
					});

					const image = current.children[0] as HTMLDivElement;
					if (image.getBoundingClientRect().right < 0 && Number(image.dataset.index) === startIndex - 1) {
						updateImages();
					}

					current.style.transform = `translateX(-${Math.round(count / 4)}px)`;
				}
			}
		};

		// Disable repeated animation on chromatic for more consistent screenshots
		if (!isChromatic()) {
			if (isDesktop) {
				setIntroAnimation(false);
				galleryInterval = setInterval(updateImages, 6500);
			} else {
				maxImages = 6;
				galleryInterval = setInterval(moveGallery, 1000 / 60);
			}
		}

		updateImages();

		return () => {
			window.clearInterval(galleryInterval);

			if (!current) return;
			Array.from(current.children).forEach((el: HTMLDivElement) => {
				el.style.left = '';
				current.style.transform = '';
				delete el.dataset.positioned;
			});
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps -- Listen only for width changes to prevent scrolling on mobile to restart animation
	}, [size.width]);

	return (
		<Theme palette={getPallette(ColorTheme.Darkblue)} className={cn(styles.hero, styles.has_color_theme)}>
			<Container>
				<div className={styles.top}>
					<div className={styles.images} ref={imagesRef}>
						<AnimatePresence>
							{currentImagesRef.current.map((image, i) => (
								<motion.div
									key={image.index}
									data-index={image.index}
									className={cn(styles.image, styles[`size-${1 + (image.index % 4)}`])}
									{...getImageAnimation(i)}
								>
									<QBankMediaDisplay
										className={styles.image__inner}
										media={image}
										imageProps={{
											priority: true,
										}}
										aspectRatio={Aspect['1:1']}
										sizes={`
            					(max-width: 959px) 33vw,
            					25vw`}
									/>
								</motion.div>
							))}
						</AnimatePresence>
					</div>
					<div className={styles.body}>
						<HeroLine />
						<motion.h1 {...MOTIONS['in-right']['fade']()} transition={{ duration: 1 }} className="h0">
							{tagline}
						</motion.h1>
						<motion.div {...MOTIONS['fade']['fade']()} transition={{ duration: 1, delay: 2 }}>
							<UmbracoLink
								link={action}
								variant={'fill-secondary'}
								icon={{ icon: 'arrow-right' }}
								className={styles.button}
								size="large"
							>
								{action.name}
							</UmbracoLink>
						</motion.div>
					</div>
				</div>
				<motion.div className={styles.usps} {...MOTIONS['in-up']['fade']()} transition={{ duration: 1, delay: 2 }}>
					{uspList.map(({ key, contentProperties: { text } }) => (
						<div key={key}>
							<Icon icon="checkmark-fancy" size={24} color={vars['--color-signal-yellow']} />
							{text}
						</div>
					))}
				</motion.div>
			</Container>
		</Theme>
	);
}

/**
 * Return an array of items from start to end, wrapping around if needed
 */
function continousSlice(arr: QBankImageType[], start, end) {
	const result: ExtendedQBankImageType[] = [];

	if (arr.length === 0) return result;

	// eslint-disable-next-line no-plusplus
	for (let index = start; index < end; index++) {
		const current = index % arr.length;
		result.push({ ...arr[current], index });
	}

	return result;
}
